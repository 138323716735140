
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'
import { IViewAction, ActionStyle, Allign, ActionPosition } from '@/modules/system/types'

@Component
export default class BaseForm extends Vue {
  @Prop({ default: false })
  readonly darkTitle!: boolean | string;

  @Prop({ default: false })
  readonly hideTitle!: boolean | string;

  @Prop({ default: false })
  readonly hideTitleDivider!: boolean | string;

  @Prop({ default: false })
  readonly hideHeader!: boolean | string;

  @Prop(String)
  readonly caption: string | undefined;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop([String, Number])
  readonly maxWidth: string | number | undefined;

  @Prop({ default: false })
  readonly flat!: boolean;

  @Prop({ default: false })
  readonly fluid!: boolean | string;

  @Prop({ default: 'center' })
  readonly actionsAlign!: Allign;

  @Prop({ default: false })
  readonly actionsRounded!: boolean;

  @Prop({ default: 'flat' })
  readonly actionsStyle!: ActionStyle;

  @Prop({ default: 'bottom' })
  readonly actionsPosition!: ActionPosition;

  @Prop({ default: () => [] })
  readonly actions!: Array<IViewAction>;

  @Prop(String)
  readonly color: string | undefined;

  @Prop(String)
  readonly titleColor: string | undefined;

  @Prop([String, Number])
  readonly height: string | number | undefined;

  common = $app.module(Common);
  system = $app.module(System);
  fab = false;
  scroll = false;
  offsetTop = 0;

  get showHead(): boolean {
    if (this.offsetTop > 0) {
      this.scroll = false;
      return false;
    } else {
      if (this.scroll) {
        return true;
      } else {
        setTimeout(() => {
          this.offsetTop = 1;
          this.scroll = true;
        }, 200);
        return false;
      }
    }
  }

  onScroll(e: any) {
    this.$emit('scroll', e);
  }
}
